.vidio_bg .video-wrapper .Icon {
    display: none;
}

.vidio_bg .video-wrapper:hover .Icon {

    display: block;
}
.hidden{
    background-color: pink;
}

/* .Icon {

 position: absolute ;
 text-align: center ;
 color: white ;
 left: 50% ;
 top: 49% ;
 z-index: 99999 ;
 font-size: 30px ;
 width: 100px ;
 height: 100px ;
 border-radius: 60px ;
 background: black 
} */

.container-fluid{
    padding:0px 15px;
}

@media screen and(min-width:768px) {
    .vidio_cu{
        height:100% !important;
    }
}
@media screen and (max-width:767px) {
    .vidio_cu{
        height:80% !important;
    }
}

.container {
    display: flex;
    justify-content: center;
    align-items: top;
    height: 100vh;
    width: 100%;
}

:root {
    --input-padding-x: 0rem !important;
    --input-padding-y: 0rem !important;
}

.menu_con {
    background: rgb(18, 18 ,18);
    min-height: 100vh;
}