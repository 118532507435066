.sidebar::-webkit-scrollbar {
	width: 6px;
	background-color: #f5f5f5;
}
.sidebar::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #555;
}

.channel-sidebar-list {
	border-top: 1px solid #2e2e2e;
	margin: 6px 0 0;
	padding: 7px 14px;
}
.channel-sidebar-list h6 {
	color: #fff !important;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 15px;
	margin-top: 9px;
}
.channel-sidebar-list ul {
	list-style: outside none none;
	margin: 0;
	padding: 0;
}
.channel-sidebar-list ul li img {
	border-radius: 50px;
	height: 28px;
	margin: 0 14px 0 0;
	width: 28px;
}
.channel-sidebar-list li a {
	color: #fff;
	display: inline-block;
	overflow: hidden;
	padding: 6px 0;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}
.channel-sidebar-list li a .badge {
	float: right;
	margin: 7px 0 0;
	position: absolute;
	right: 20px;
}
.sidebar.toggled .nav-item .nav-link span {
	margin: 0;
}
.sidebar.toggled .channel-sidebar-list h6 {
	font-size: 11px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.sidebar.toggled .channel-sidebar-list {
	padding: 7px;
	text-align: center;
}
.sidebar.toggled .channel-sidebar-list li img {
	display: list-item;
	margin: auto auto 4px;
}
.sidebar.toggled .channel-sidebar-list li a {
	font-size: 10px;
	font-weight: 500;
}
.sidebar.toggled .channel-sidebar-list li .badge {
	display: none;
}

.sidebar {
	width: 90px !important;
	background: #1c1c1c;

	/* Old browsers */
	/* background: -moz-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%); */
	/* FF3.6-15 */
	/* background: -webkit-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%); */
	/* Chrome10-25,Safari5.1-6 */
	/* background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%); */
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff516b', endColorstr='#826cfd',GradientType=1 );
	/* IE6-9 fallback on horizontal gradient */
	/* min-height: 100%; */
	z-index: 9;
	padding: 2px 0;
	position: fixed;
	/* height: 100%; */
}
.sidebar:hover {
	overflow: auto;
}

.sidebar .nav-item:last-child {
	margin-bottom: 1rem;
}
.sidebar .nav-item .nav-link {
	font-size: 14px;
	padding: 12px 14px !important;
	text-align: center;
	width: 90px;
	font-weight: 600;
}
.sidebar .nav-item .nav-link:hover,
.sidebar .nav-item.active .nav-link {
	background-color: rgba(255, 255, 255, 0.2) !important;
}
.sidebar .nav-item .nav-link .fas {
	color: #ffffff;
}
.sidebar .nav-item .nav-link span {
	display: block;
    font-size: 0.65rem;
	/* margin: 0 0 0 11px; */
}
.sidebar .nav-item .dropdown-menu {
	position: absolute !important;
	-webkit-transform: none !important;
	transform: none !important;
	left: calc(90px + 0rem) !important;
	margin: 0;
}
.sidebar .dropdown-menu.show {
	margin-left: 1rem !important;
	margin-right: 1rem !important;
}

.sidebar .nav-item .dropdown-menu.dropup {
	bottom: 0;
	top: auto !important;
}
.sidebar .nav-item.dropdown .dropdown-toggle::after {
	display: none;
}
.sidebar .nav-item .nav-link {
	color: #ffffff;
}
.sidebar .nav-item .nav-link:active,
.sidebar .nav-item .nav-link:focus,
.sidebar .nav-item .nav-link:hover {
	color: rgba(255, 255, 255, 0.75);
}
/* .sidebar.toggled {
	width: 0 !important;
	overflow: hidden;
} */

@media (max-width: 575.98px) {
	.sidebar .nav-item .nav-link {
		padding: 8px 14px !important;
	}
	.sidebar .nav-item .nav-link span {
		margin: 0 !important;
	}
	.sidebar .channel-sidebar-list h6 {
		font-size: 11px !important;
	}
	.sidebar .nav-item .nav-link span {
		display: block;
		font-size: 0.65rem;
	}
	.sidebar .channel-sidebar-list h6 {
		font-size: 11px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.sidebar .channel-sidebar-list {
		padding: 7px;
		text-align: center;
	}
	.sidebar .channel-sidebar-list li img {
		display: list-item;
		margin: auto auto 4px;
	}
	.sidebar .channel-sidebar-list li a {
		font-size: 10px;
		font-weight: 500;
	}
	.sidebar .channel-sidebar-list li .badge {
		display: none;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.sidebar .nav-item .nav-link {
		padding: 8px 14px !important;
	}

	.sidebar .nav-item .nav-link span {
		margin: 0 !important;
	}
	.sidebar .channel-sidebar-list h6 {
		font-size: 11px !important;
	}
	.sidebar .nav-item .nav-link span {
		display: block;
		font-size: 0.65rem;
	}
	.sidebar .channel-sidebar-list h6 {
		font-size: 11px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.sidebar .channel-sidebar-list {
		padding: 7px;
		text-align: center;
	}
	.sidebar .channel-sidebar-list li img {
		display: list-item;
		margin: auto auto 4px;
	}
	.sidebar .channel-sidebar-list li a {
		font-size: 10px;
		font-weight: 500;
	}
	.sidebar .channel-sidebar-list li .badge {
		display: none;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.sidebar .nav-item .nav-link {
		padding: 8px 14px !important;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.sidebar .nav-item .nav-link {
		padding: 8px 14px !important;
	}
}

/*///////////////////////////////////////////////////*/
@media (min-width: 768px) {
	.sidebar {
		/* height: 100%; */
		position: fixed;
		width: 225px !important;
	}
	.sidebar:hover {
		overflow: auto;
	}
	.sidebar .nav-item .nav-link {
		display: block;
		width: 100%;
		text-align: left;
		padding: 1rem;
		width: 225px;
	}
	/* .sidebar .nav-item .nav-link span {
		display: inline;
		font-size: 13px;
		margin: 0 0 0 11px;
	} */
	.sidebar .nav-item .dropdown-menu {
		position: static !important;
		margin: 0 1rem;
		top: 0;
	}
	.sidebar .nav-item.dropdown .dropdown-toggle::after {
		display: block;
	}
	.sidebar.toggled {
		overflow: visible;
		width: 90px !important;
	}
	.sidebar.toggled:hover {
		/* overflow-x: auto; */
	}
	.sidebar.toggled .nav-item:last-child {
		margin-bottom: 1rem;
	}
	.sidebar.toggled .nav-item .nav-link {
		text-align: center;
		padding: 0.75rem 1rem;
		width: 90px;
	}
	.sidebar.toggled .nav-item .nav-link span {
		margin: 0;
	}
	.sidebar.toggled .nav-item .nav-link span {
		font-size: 0.65rem;
		display: block;
	}
	.sidebar.toggled .nav-item .dropdown-menu {
		position: absolute !important;
		-webkit-transform: none !important;
		transform: none !important;
		left: calc(90px + 0.5rem) !important;
		margin: 0;
	}
	.sidebar.toggled .nav-item .dropdown-menu.dropup {
		bottom: 0;
		top: auto !important;
	}
	.sidebar.toggled .nav-item.dropdown .dropdown-toggle::after {
		display: none;
	}
}

.sidebar.fixed-top {
	top: 56px;
	height: calc(100vh - 56px);
	overflow-y: auto;
}

.container {
    width: calc(100% - 225px);
    max-width: calc(100% - 225px);
    background: #000;
	height: calc(100vh - 63px) !important;
}

.video-wrapper {
    height: calc(100vh - 63px);
	background-color: black;
	overflow-y: hidden !important;
}

@media screen and (max-width:767px) {
	.video-wrapper {
		height: calc(100vh - 94px);
	}
}
.sidebar {
    height: calc(100vh - 63px);
}
