.cut-text{
    margin-left: 5px;
}

.main_item{
    -webkit-box-sizing: border-box;
    display: inline-block;
    -webkit-transition: all 1s ease;
    width: 100%;
}

.scroll-left {
    height: 30px;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    margin-top:5px;
}

.scroll-left h6:hover {
    -webkit-animation: scroll-left 10s linear infinite;
    animation: scroll-left 10s linear infinite;
    height: 100%;
    position: absolute;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%) !important;
    width: 100%;
    transition:25s;
    text-overflow: ellipsis !important;
    
}

/* .countydeatail_box{
    display: grid;
    gap: 10px;
    grid-template-columns:repeat(3,1fr);
}

@media screen and (min-width:1200px) {
   .countydeatail_box{
    grid-template-columns:repeat(10,1fr) !important;
   }
}
@media screen and (min-width:991px) {
    .countydeatail_box{
        grid-template-columns:repeat(8,1fr);
       }
}
@media screen and (min-width:768px) {
    .countydeatail_box{
        grid-template-columns:repeat(6,1fr);
       }
} */