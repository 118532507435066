.single-channel-page {
	padding: 0px 0px 0px 89px;
}

.single-channel-page .container-fluid {
	padding: 30px 30px 30px !important;
}

.sidebar-toggled .single-channel-page {
	padding: 0px 0px 0px 90px;
}

/* Single Channel */
.single-channel-image {
	position: relative;
}

.single-channel-nav .navbar {
	padding: 0;
}

.single-channel-nav {
	background: #fff none repeat scroll 0 0 !important;
	/* box-shadow: 0 0 11px #ececec; */
	padding: 0rem 30px 0;
}

.channel-brand {
	color: #000 important !important;
	font-size: 16px !important;
	font-weight: bold;
}

.single-channel-nav .nav-link {
	border-bottom: 2px solid transparent;
	margin: 0 12px;
	padding: 14px 0 !important;
}

.single-channel-nav .nav-item.active .nav-link,
.single-channel-nav .nav-item .nav-link:hover,
.single-channel-nav .nav-item.active .nav-link:active {
	border-color: #000000 !important;
	color: #000000 !important;
}

.single-channel-nav .navbar-nav {
	margin: 0 0 0 40px;
}

.channel-profile {
	bottom: 0;
	left: 0;
	padding: 1rem 30px;
	position: absolute;
	right: 0;
}

.single-channel-image .img-fluid {
	width: 100%;
}

.channel-profile-img {
	background: #fff none repeat scroll 0 0;
	border: 6px solid #fff;
	border-radius: 50px;
	height: 90px;
	width: 90px;
}

.social {
	color: #fff;
	float: right;
	margin: 62px 0 0;
}

.social a {
	background: #000 none repeat scroll 0 0;
	border-radius: 2px;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	padding: 5px 10px;
}

@media (max-width: 575.98px) {
	.single-channel-page {
		padding: 0 0 0 225px !important;
	}

	.sidebar-toggled .single-channel-page {
		padding: 0 !important;
	}

	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}

	.single-channel-nav {
		padding: 15px;
	}

	.channel-profile {
		padding: 10px;
		position: relative;
		text-align: center;
	}

	.social {
		display: none;
	}

	.navbar-toggler {
		padding: 1px 3px;
	}

	.single-channel-nav .navbar-nav {
		margin: 0;
	}

	.single-channel-nav .nav-link {
		margin: 0;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.single-channel-page {
		padding: 0 0 0 225px !important;
	}

	.sidebar-toggled .single-channel-page {
		padding: 0 !important;
	}

	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}

	.single-channel-nav {
		padding: 15px;
	}

	.channel-profile {
		padding: 10px;
		position: relative;
		text-align: center;
	}

	.social {
		display: none;
	}

	.navbar-toggler {
		padding: 1px 3px;
	}

	.single-channel-nav .navbar-nav {
		margin: 0;
	}

	.single-channel-nav .nav-link {
		margin: 0;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.single-channel-page {
		padding: 0 0 0 89px !important;
	}

	.sidebar-toggled .single-channel-page {
		padding: 0 0 0 225px !important;
	}

	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}

	.single-channel-nav {
		padding: 15px;
	}

	.channel-profile {
		padding: 15px;
	}

	.social {
		display: none;
	}

	.navbar-toggler {
		padding: 1px 3px;
	}

	.single-channel-nav .navbar-nav {
		margin: 0;
	}

	.single-channel-nav .nav-link {
		margin: 0;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.single-channel-page {
		padding: 0 0 0 89px !important;
	}

	.sidebar-toggled .single-channel-page {
		padding: 0 0 0 225px !important;
	}

	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}

	.single-channel-nav {
		padding: 0 15px;
	}

	.channel-profile {
		padding: 15px;
	}

	.social {
		display: none;
	}

	.navbar-toggler {
		padding: 1px 3px;
	}

	.single-channel-nav .navbar-nav {
		margin: 0 0 0 20px;
	}

	.single-channel-nav .nav-link {
		margin: 0 7px;
	}

	.single-channel-nav .form-control,
	.single-channel-nav .btn-outline-success {
		display: none;
	}
}


.btn-outline-danger {
	border-color: #000000 !important;
	color: #000000 !important;
}


.content-section {
	padding-top: 20px;
	/* padding-bottom: 10px; */
	padding-left: 10px;
	padding-right: 10px;
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.filter-section {
	width: 100%;
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
}

.cust_nav {
	position: absolute;
	inset: 0px auto auto 0px;
	margin: 7px 6px 6px 6px;
	transform: translate3d(-8px, 30px, 0px) !important;
}

.float-right {
	float: right !important;
}

.navbtn {
	color: white;
	background-color: #000;
	border-color: #fff;
}

/* .navbtn:hover{
	background-color: #ffffff;
} */

.poster .cu_text{
	white-space: nowrap; 
	width: 160px; 
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative !important;
}

.poster .cu_text:hover{
	animation: scroll-left 10s linear infinite;
    position: absolute;
    /* -webkit-transform: translateX(-100%); */
    /* transform: translateX(-100%); */
}
.container-fluid{
	padding-top:0 !important;
}

a:hover {
	/* color: #000 !important; */
}

.dropdown-menu{
    transform: translate3d(0px, 37px, 0px) !important;
}

.new_btn{
	padding: 0.375rem 0.75rem !important;
	line-height:2;
}


.season-dropdown {
	display: inline-block;
}

.nav-link {
	display: block;
	padding: 0 0 !important;
}

a {
	color: #fff !important;
}

/* .dpitem a:hover{
	background-color: rgba(236, 15, 15, 0.8) !important;
	
} */

.tvch {
	padding-left: 0 !important;
	padding-right: 10px !important;

}

.channelimg {
	transition: transform .1s;
	background: grey;
	/* width: 200px; */
	min-height: 70px;
	border-radius: 5px;
	height: auto;
	vertical-align: middle;
	border-style: none;
}

.channels {
	position: relative;
	padding: 5px;
	padding-right: 0;
	display: inline-block;
	transition: transform .1s;
}


@media screen and (min-width: 1475px) {
	.col.tvch {
		width: 10% !important;
		min-width: 10% !important;
		max-width: 10% !important;
	}
}


@media (max-width: 1475px) and (min-width: 960px) {
	.col.tvch {
		width: 12.5% !important;
		min-width: 12.5% !important;
		max-width: 12.5% !important;
	}
}

@media (min-width: 600px) and (max-width: 960px) {
	.col.tvch {
		width: 33.3% !important;
		min-width: 33.3% !important;
		max-width: 33.3% !important;
	}
}

@media (min-width: 300px) and (max-width: 600px) {
	.col.tvch {
		width: 50% !important;
		min-width: 50% !important;
		max-width: 50% !important;
	}
}

@media (max-width: 300px) {
	.col.tvch {
		width: 100% !important;
		min-width: 100% !important;
		max-width: 100% !important;
	}
}