.single-channel-page{
    position: relative;
}

.toni_bg{
    /* background-image: url({item.profile_image}); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute !important;
    left:0;
    border-radius:5px;
    background-attachment: fixed;
    width:100%;
    height:330px;
    opacity:0.6;
}
.cut_btn{
    gap:5px;
}

.btn_cu1{
    gap:2px;
    max-width:100px;
}

.btn_cu1 span{
    font-size:16px !important;
}

.New_Other_btn{
    gap:8px;
    width: calc(100% - 100px);
}


.single-channel-page .toni_content{
    padding:20px 10px 10px 10px !important;
}

.ac_content{
    top:200px;
    left: 40px;
    z-index:999;
}

.acotrlogo{
    width:200px  !important;
    min-height:250px  !important;
    object-fit: contain;
    border-radius: 5px;
}
.acotrlogo img{
    width: 250px !important;
}

.actor_con{
    margin-left:25px;
}

.acotrlogo img{
    box-shadow: 0 0 10px #000;
    height:100%;
    width:100%;
    object-fit: contain;
}

.actor_con h3{
  font-size:22px;
  text-transform: uppercase !important;
  font-weight:700;
}

.actor_con p{
    font-size:13px;
    font-weight:700;
}

.tonisub_content{
    padding:20px 0 0 0 ;
}

.tonisub_content{
    padding-top:520px;
}

.tonisub_content h6{
    font-size:17px;
    font-weight:700;
}

.tonisub_content p{
    font-size:16px;
    font-weight:400;
    color: rgb(179, 179, 179);
    line-height:24px;
}

.fli_text{
    padding-top:20px;
}

.tonisub_img{
    height:225px;
    width:150px;
    border-radius:5px;
}

.tonisub_img img{
    height:100%  !important;
    width:100%;

    overflow: hidden;
}

