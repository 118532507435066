.cut_btn {
    gap: 5px;
}

.btn_cu {
    width: 82px;
}
.btn_cu span{
    margin-right:4px !important;
}

.oth_btn {
    width: calc(100% - 82px);
    gap: 5px;
}

.cu_bg {
    position: relative;
    display: inline-block;
    border-radius: 18px;
    transition: transform .1s;
}

.cu_bg img {
    box-shadow: 0 0 5px 2px #4a4a4a59;
}

.uper {
    background: linear-gradient(0deg, #000010d1 10%, rgba(0, 0, 0, 0.32) 100%);
}

.cu_bg .uper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
}

.uper>span {
    position: absolute;
    width: 100%;
    top: 50%;
    padding: 8px;
    transform: translateY(-25%);
}

.uper h4 {
    color: #fff;
    width: 100%;
    white-space: pre-line;
    font-size: 17pt;
    font-weight: 700;
}

.uper p {
    font-size: 10pt !important;
    width: 100%;
    white-space: pre-wrap;
    margin-top: 10px;
    margin-bottom: 0;
    color: #aaa !important;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.img_boxes span p {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}





@media screen and (max-width:575px) {
    .uper h4 {
        font-size: 13pt;
    }
}