.homepage-wrapper{
    overflow: hidden;
    overflow-y: auto;
    max-height: 100vh;
    position:relative !important;

    
}

.example::-webkit-scrollbar {
    display: none;
  }