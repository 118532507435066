.box_img{
    height:284px;
    /* height:100%; */
    width: 100%;
    border-radius: 6px;
    object-fit: cover;
}

.box_img img{
    object-fit: cover !important;
}

.genes_text{
    color: white;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
}

@media screen and (max-width:576px) {
    .genes_text{
    font-size:18px;
   }
}

@media screen and (max-width:400px) {

    .genes_text{
        font-size:13px;
       }
 }