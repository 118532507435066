.channels-card {
	background: #1c1c1c none repeat scroll 0 0;
	border-radius: 2px;
	/* box-shadow: 0 0 11px #ececec; */
	padding: 24px 12px;
	position: relative;
	text-align: center;
	transition-duration: 0.4s;
}
.channels-card::after {
	background: #dcdcdc none repeat scroll 0 0;
	border-radius: 0 0 5px 6px;
	bottom: -4px;
	content: "";
	height: 7px;
	left: 15px;
	position: absolute;
	right: 15px;
	z-index: -1;
}
.channels-card:hover::after {
	background: #cccccc none repeat scroll 0 0;
}
.channels-title a {
	color: white !important;
	font-weight: 500;
	text-decoration: none;
}
.channels-view {
	color: #acacac;
}
.channels-card-image .btn {
	font-size: 12px;
	padding: 3px 10px;
}
.channels-card-image .btn strong {
	margin: 0 0 0 5px;
}
.channels-card-image img {
	background: #fff none repeat scroll 0 0;
	border-radius: 50px;
	box-shadow: 0 0 2px #ccc;
	height: 80px;
	margin: 1px 0 8px;
	object-fit: scale-down;
	transform: scale(1);
	width: 80px;
}
.channels-card-image-btn {
	margin: 3px 0 7px;
}
