.single-channel-page {
	padding: 0px 0px 0px 225px;
}

.single-channel-page .container-fluid {
	padding: 30px 30px 30px !important;
}

.sidebar-toggled .single-channel-page {
	padding: 0px 0px 0px 90px;
}

/* Single Channel */
.single-channel-image {
	position: relative;
}

.single-channel-nav .navbar {
	padding: 0;
}

.single-channel-nav {
	background: #fff none repeat scroll 0 0 !important;
	box-shadow: 0 0 11px #ececec;
	padding: 0rem 30px 0;
}

.channel-brand {
	color: #000 important !important;
	font-size: 16px !important;
	font-weight: bold;
}

.single-channel-nav .nav-link {
	border-bottom: 2px solid transparent;
	margin: 0 12px;
	padding: 14px 0 !important;
}

.single-channel-nav .nav-item.active .nav-link,
.single-channel-nav .nav-item .nav-link:hover,
.single-channel-nav .nav-item.active .nav-link:active {
	border-color: #ff516b !important;
	color: #ff516b !important;
}

.single-channel-nav .navbar-nav {
	margin: 0 0 0 40px;
}

.channel-profile {
	bottom: 0;
	left: 0;
	padding: 1rem 30px;
	position: absolute;
	right: 0;
}

.single-channel-image .img-fluid {
	width: 100%;
}

.channel-profile-img {
	background: #fff none repeat scroll 0 0;
	border: 6px solid #fff;
	border-radius: 50px;
	height: 90px;
	width: 90px;
}

.social {
	color: #fff;
	float: right;
	margin: 62px 0 0;
}

.social a {
	background: #000 none repeat scroll 0 0;
	border-radius: 2px;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	padding: 5px 10px;
}
@media (max-width: 820px) {
	.movie-title{
	bottom: 136px !important;
}
}
@media (max-width: 575.98px) {
	

	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}

	.single-channel-nav {
		padding: 15px;
	}

	.channel-profile {
		padding: 10px;
		position: relative;
		text-align: center;
	}

	.social {
		display: none;
	}

	.navbar-toggler {
		padding: 1px 3px;
	}

	.single-channel-nav .navbar-nav {
		margin: 0;
	}

	.single-channel-nav .nav-link {
		margin: 0;
	}

	.poster {
		padding: 5px !important;
	}
	
}

@media (min-width: 576px) and (max-width: 767.98px) {
	

	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}

	.single-channel-nav {
		padding: 15px;
	}

	.channel-profile {
		padding: 10px;
		position: relative;
		text-align: center;
	}

	.social {
		display: none;
	}

	.navbar-toggler {
		padding: 1px 3px;
	}

	.single-channel-nav .navbar-nav {
		margin: 0;
	}

	.single-channel-nav .nav-link {
		margin: 0;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	

	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}

	.single-channel-nav {
		padding: 15px;
	}

	.channel-profile {
		padding: 15px;
	}

	.social {
		display: none;
	}

	.navbar-toggler {
		padding: 1px 3px;
	}

	.single-channel-nav .navbar-nav {
		margin: 0;
	}

	.single-channel-nav .nav-link {
		margin: 0;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	

	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}

	.single-channel-nav {
		padding: 0 15px;
	}

	.channel-profile {
		padding: 15px;
	}

	.social {
		display: none;
	}

	.navbar-toggler {
		padding: 1px 3px;
	}

	.single-channel-nav .navbar-nav {
		margin: 0 0 0 20px;
	}

	.single-channel-nav .nav-link {
		margin: 0 7px;
	}

	.single-channel-nav .form-control,
	.single-channel-nav .btn-outline-success {
		display: none;
	}
}
.movie-bigtitle{
	position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.movie-maintitle h2{
	font-size: 20pt;
    text-transform: uppercase;
    font-weight: 700;
	color: #fff;
	background-color: #0e0e1b;
	padding: 15px;
	display: inline-block;
	margin-bottom: 0px;
}
.movie-smalltitle{
	display:  flex;
	flex-wrap: wrap;
	gap: 12px;
	justify-content: space-between;
	align-items: center;
}
.movie-lefttitle{
	font-size: 16px;
	color: #fff;
	background-color: #0e0e1b;
	padding: 15px;
	margin-bottom: 0px;
	display: inline-block;
}
.movie-righttitle{
	font-size: 16px;
	color: #fff;
	background-color: #0e0e1b;
	padding: 0 15px;
	margin-bottom: 0px;
	display: inline-block;
}

.similar_con{
	display: grid;
	gap:16px;
}

@media screen and (min-width:1200px) {
	.similar_con{
		gap:16px !important;
	}
}
@media screen and (min-width:768px) {
	.similar_con{
		grid-template-columns: repeat(10,1fr);
		gap:8px;
	}
}
@media screen and (max-width:767px) {
	.similar_con{
		grid-template-columns: repeat(3,1fr);
	}
}

.movie-header-bg {
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: cover;
	border-radius: 5px;
	background-attachment: fixed;
	z-index: -1;
}

.movie-header {
	height: 330px;
	width: 100%;
	position: relative;
	margin-bottom: 40px;
	opacity:0.8;
}

.movie_header{
	height: 400px;
	width: 100%;
	position: relative;
	margin-bottom: 40px;
	opacity:0.8;
	background: linear-gradient(0deg,#000010 0%,rgba(0,0,0,0) 99%) !important;
}

.channel_logo{
	position: absolute;
    width: 300px;
    margin-left: 50%;
    left: -150px;
    top: 29%;
    border-radius: 5px;
    box-shadow: 0 0 10px #000;
}

.movie-title {
	font-size: 20pt;
	text-transform: uppercase;
	position: absolute;
	bottom: 80px;
	margin-left: 16px;
	font-weight: 700;
}

.movie_title{
	font-size: 20pt;
	text-transform: uppercase;
	position: absolute;
	bottom: 40px;
	margin-left: 16px;
	font-weight: 700;
}

.movie-infos {
	font-size: 11pt;
	position: absolute;
	bottom: 10px;
	margin-left: 0px;
	font-weight: 700;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
}
.movie-infos > span,
.movie-infos > .header-ratings{
	background-color: #0e0e1b;
	color: #fff;
}



.dark-mode .movie-infos {
	color: #b3b3b3 !important;
}

.dark-mode .movie-title {
	color: #fff;
}

dark-mode .movie-header {
	/* color: black; */
	background: #000010;
	background: linear-gradient(0deg, #000010 0%, rgba(0, 0, 0, 0) 99%);
}

.header-buttons {
	position: absolute;
	right: 20px;
	bottom: 47px;
}

.dark-mode .header-ratings {
	color: #b3b3b3;
}

/* .header-ratings {
	position: absolute;
	right: 20px;
	bottom: 10px;
	font-size: 12pt;
	font-weight: 700;
} */

.play-btn:hover {
	background: #b3b3b3;
}

.play-btn {
	margin-right: 5px;
	font-weight: 700;
	border: none;
	padding: 15px;
	background: #232323;
	color: #ffffff;
}

.padding-top-20 {
	padding-top: 20px;
}

.movie-section-title {
	font-size: 15pt;
	font-weight: 700;
	display: inline-block;
	color: #eee
}

.movie-setion-content {
	font-size: 12pt;
	font-weight: 500;
}

.flix-carousel {
	position: relative;
	width: 100%;
	/* min-height: 100px; */
	margin-bottom: 165px;
}

.prev_btn {
	display: none;
	width: 40px;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 3;
	border: none;
	font-size: 15pt;
}

.next_btn {
	width: 40px;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 3;
	border: none;
	font-size: 15pt;
}

.flix-scroll-y {
	width: 100%;
	/* overflow-x: scroll; */
	/* overflow-y: hidden; */
	white-space: nowrap;
	position: relative;

}

.actor {
	margin-top: 20px;
	margin-bottom: 10px;
	margin-right: 10px;
	display: inline-table;
	text-align: center;
	transition: transform .1s;
	text-decoration: none !important;
	width: 150px;
}



.actor span {
	font-size: 11pt;
	text-align: center;
	display: block;
	font-weight: 800;
	line-height: 1.2em;
	white-space: normal;
	max-width: 100%;
	color: #eee
}

.actor label {
	display: inline-block;
	margin-bottom: 0.5rem;
	color: #ccc;
	font-size: 10pt;
}

.videoimg {
	min-height: 200px;
	border-radius: 5px;
	height: auto
}

.poster {
	position: relative;
	padding: 5px;
	
	transition: transform .1s;
	margin-bottom: 0px;
	position: relative;
	margin-top: 0px;
}

.comment-section {
	margin-top: 20px;
	max-height: 600px;
	overflow: auto;
	overflow-x: hidden;
}

.success-comment,
.error-comment {
	margin-top: 15px;
}

.alert-success {
	color: #155724;
	background-color: #d4edda;
	border-color: #c3e6cb;
}

.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
}


.comment {
	margin-top: 15px;
}

.alert-danger {
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
}

.comment-btn-item {
	width: 100%;
	padding: 15px;
	font-size: 14pt;
	border-radius: 2px;
	margin-top: 10px;
	border: none;
}

.checked {
	color: red;
}

.channeldetailslide {
	position: relative;
	padding: 10px;
	padding-right: 0;
	display: inline-block;
	transition: transform .1s;
	margin-bottom: 5px;
	margin-top: 5px;
}

.channeldetailslide img {
	transition: transform .1s;
	background: grey;
	width: 200px;
	min-height: 70px;
	border-radius: 5px;
	height: auto;
}

.fatfooter {
	margin-left: 20px;
	margin-right: 20px;
}