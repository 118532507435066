.grid {
    column-count: 10;
    column-gap: .5em;
    margin: auto;
    width: 100%;
    padding : .5em;
    
  }
  
 .grid_item {
    height: auto;
    max-width: 100%;
	padding-bottom: .5em;
    
  }
  @media (max-width: 1199px) {
    .grid {
      column-count: 8;
  }
}
  @media(max-width: 1024px) {
    .grid {
      column-count: 6;
  }
}
  
  @media(max-width: 767px) {
      .grid {
        column-count: 3;
    }
  }
  
  /* @media(max-width: 600px) {
      .grid {
        column-count: 3;
    }
  }
  
  @media(max-width: 400px) {
      .grid {
        column-count: 3;
    }
  } */

.single-channel-page {
	padding: 0px 0px 0px 90px !important;
}

.single-channel-page .container-fluid {
	padding: 30px 30px 30px !important;
}

.sidebar-toggled .single-channel-page {
	padding: 0px 0px 0px 90px;
}

/* Single Channel */
.single-channel-image {
	position: relative;
}
.single-channel-nav .navbar {
	padding: 0;
}
.single-channel-nav {
	background: #fff none repeat scroll 0 0 !important;
	/* box-shadow: 0 0 11px #ececec; */
	padding: 0rem 30px 0;
}
.channel-brand {
	color: #000 important !important;
	font-size: 16px !important;
	font-weight: bold;
}
.single-channel-nav .nav-link {
	border-bottom: 2px solid transparent;
	margin: 0 12px;
	padding: 14px 0 !important;
}
.single-channel-nav .nav-item.active .nav-link,
.single-channel-nav .nav-item .nav-link:hover,
.single-channel-nav .nav-item.active .nav-link:active {
	border-color: #000000 !important;
	color: #000000 !important;
}
.single-channel-nav .navbar-nav {
	margin: 0 0 0 40px;
}
.channel-profile {
	bottom: 0;
	left: 0;
	padding: 1rem 30px;
	position: absolute;
	right: 0;
}
.single-channel-image .img-fluid {
	width: 100%;
}
.filter-section1{
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	padding: 0 10px;
	flex-wrap: wrap;
}
.channel-profile-img {
	background: #fff none repeat scroll 0 0;
	border: 6px solid #fff;
	border-radius: 50px;
	height: 90px;
	width: 90px;
}
.social {
	color: #fff;
	float: right;
	margin: 62px 0 0;
}
.social a {
	background: #000 none repeat scroll 0 0;
	border-radius: 2px;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	padding: 5px 10px;
}

.content-section {
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0!important;
    margin-left: 0!important;
}

@media (max-width: 575.98px) {
	
	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}
	.single-channel-nav {
		padding: 15px;
	}
	.channel-profile {
		padding: 10px;
		position: relative;
		text-align: center;
	}
	.social {
		display: none;
	}
	.navbar-toggler {
		padding: 1px 3px;
	}
	.single-channel-nav .navbar-nav {
		margin: 0;
	}
	.single-channel-nav .nav-link {
		margin: 0;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	
	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}
	.single-channel-nav {
		padding: 15px;
	}
	.channel-profile {
		padding: 10px;
		position: relative;
		text-align: center;
	}
	.social {
		display: none;
	}
	.navbar-toggler {
		padding: 1px 3px;
	}
	.single-channel-nav .navbar-nav {
		margin: 0;
	}
	.single-channel-nav .nav-link {
		margin: 0;
	}
	
}

@media (min-width: 768px) and (max-width: 991.98px) {
	
	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}
	.single-channel-nav {
		padding: 15px;
	}
	.channel-profile {
		padding: 15px;
	}
	.social {
		display: none;
	}
	.navbar-toggler {
		padding: 1px 3px;
	}
	.single-channel-nav .navbar-nav {
		margin: 0;
	}
	.single-channel-nav .nav-link {
		margin: 0;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	
	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}
	.single-channel-nav {
		padding: 0 15px;
	}
	.channel-profile {
		padding: 15px;
	}
	.social {
		display: none;
	}
	.navbar-toggler {
		padding: 1px 3px;
	}
	.single-channel-nav .navbar-nav {
		margin: 0 0 0 20px;
	}
	.single-channel-nav .nav-link {
		margin: 0 7px;
	}
	.single-channel-nav .form-control,
	.single-channel-nav .btn-outline-success {
		display: none;
	}
}


@media screen and (min-width: 1475px) {
	.col.mgz {
		width: 12.5% !important;
	}
	
}


.btn-outline-danger {
    border-color: #000000 !important;
    color: #000000 !important;
}

.loadmore{
	background: #ff516b none repeat scroll 0 0;
	border-radius: 2px;
	color: #fff;
	height: 30px;
	margin: 0 7px;
	text-align: center;
	/* width: 30px; */
	cursor: pointer;
}



.filter-section {
    width: 100%;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}

.float-right {
    float: right!important;
}

.navbtn{
	color:white;
	background-color: #000;
	border-color: #fff;
}

/* .navbtn:hover{
	background-color: #ffffff;
} */

/* a:hover{
	color: #000 ;
} */

.season-dropdown{
	display: inline-block;
}

.nav-link {
    display: block;
    padding: 0 0 !important;
}

a {
	color:#fff !important;
}

/* .dpitem a:hover{
	background-color: rgba(236, 15, 15, 0.8) !important;
	
} */

.mgz {
	padding-left: 0 !important;
	padding-right: 20px !important;
	
}

.videoimg{
	min-height: 200px;
    border-radius: 5px;
	height:auto
}


@media screen and (min-width: 1475px) {
	.col.mgz {
		width: 10% !important;
		min-width: 10% !important;
		max-width: 10% !important;
	}
}


@media (max-width: 1475px) and (min-width: 960px) {
	.col.mgz {
		width: 12.5% !important;
		min-width: 12.5% !important;
		max-width: 12.5% !important;
	}
}

@media (min-width: 600px) and (max-width: 960px) {
	.col.mgz {
		width: 33.3% !important;
		min-width: 33.3% !important;
		max-width: 33.3% !important;
	}
}

@media (min-width: 300px) and (max-width: 600px) {
	.col.mgz {
		width: 50% !important;
		min-width: 50% !important;
		max-width: 50% !important;
	}
}

@media (max-width: 300px) {
	.col.mgz {
		width: 100% !important;
		min-width: 100% !important;
		max-width: 100% !important;
	}
}
@media (min-width: 1700px) {
	.col-xxl-2 {
		-ms-flex: 0 0 10%;
		flex: 0 0 10%;
		max-width: 10%;
	}	
}